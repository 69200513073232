<template>
  <div class="empty">
    <div class="empty__image">
      <img :src="image" alt="Here is not thing." />
    </div>
    <p class="empty__description">{{ description }}</p>
  </div>
</template>

<script>
export default {
  props: {
    description: {
      type: String,
      default: '这里什么都没有发现',
    },
    image: {
      type: String,
      default: 'https://img.yzcdn.cn/vant/empty-image-default.png',
    },
  },
};
</script>

<style lang="scss" scoped>
.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 186px 0;
  padding-top: 200px;

  &__image {
    width: 240px;
    height: 206px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__description {
    margin-top: 16px;
    padding: 0 60px;
    color: #515a6e;
    font-size: 16px;
    line-height: 20px;
  }
}
</style>
