<template>
  <div id="sponsorManagement" v-if="!authPick" :style="{ width: tableWidth + ' !important' }">
    <filter-pane ref="filterPane">
      <template v-slot:filter>
        <div class="searchForm">
          <el-input v-model="dataForm.nameContains" placeholder="键入申办方查询" class="searchInput" @input="searchForm"></el-input>
          <div>
            <el-button type="default" v-if="authGroup.create" size="mini" class="addButton" @click="addServiceItem">添加申办方</el-button>
            <el-button type="primary" v-if="authGroup.create || authGroup.update" size="mini" class="saveButton" @click="saveAllServiceItems"
              >保存</el-button
            >
          </div>
        </div>
      </template>
    </filter-pane>
    <EditServiceItemTable
      :applyComp="'sponsor'"
      :tableLoading.sync="tableLoading"
      :serviceTableData="serviceTableData"
      :chooseInputArr="chooseInputArr"
      :tableHeight="tableHeight"
      @deleteData="deleteData"
      @record="record"
      :requiredIndex="requiredIndex"
      :saveKey.sync="saveKey"
      :page.sync="dataForm.page"
      :pagesize.sync="dataForm.pagesize"
      @searchForm="searchForm"
      :authGroup="authGroup"
      :count="count"
      @pageChange="pageChange"
    ></EditServiceItemTable>
  </div>
  <div v-else>
    <Empty v-if="shouldShowErrimg" :class="{ show_empty: shouldShowErrimg }" :image="errImg" :description="errInfo" />
  </div>
</template>

<script>
import { debounce } from '@/utils/debounce';
import resourceManagement from '@/api/modules/resourceManagement.api.js';
import Empty from '@/components/Empty/index.vue';
import FilterPane from '@/views/project-data/components/FilterPane';
import EditServiceItemTable from './components/EditServiceItemTable';

import { mixinsResource } from '@/mixins/resourceData';

export default {
  components: {
    EditServiceItemTable,
    FilterPane,
    Empty,
  },
  name: 'SponsorManagement',
  mixins: [mixinsResource],

  created() {
    this.authGroup.read = this.$store.state.app.userConfig.permissionsKeys?.SponsorManagementRead;
    this.authGroup.create = this.$store.state.app.userConfig.permissionsKeys?.SponsorManagementCreate;
    this.authGroup.update = this.$store.state.app.userConfig.permissionsKeys?.SponsorManagementUpdate;
    this.authGroup.del = this.$store.state.app.userConfig.permissionsKeys?.SponsorManagementDelete;
    this.refreshData();
  },
  data() {
    return {
      authGroup: {
        read: false,
        create: false,
        delete: false,
        update: false,
      },
      requiredIndex: [],
      serviceTableData: [],
      count: 0,
      chooseInputArr: [
        {
          prop: 'name',
          editable: true,
        },
        {
          prop: 'fullname',
          width: 400,
          editable: true,
        },
        {
          prop: 'remark',
          editable: true,
        },
        {
          prop: 'creator',
          editable: false,
        },
        {
          prop: 'createdAt',
          editable: false,
        },
      ],
      choosedContractItem: {}, //当前选择的事项
      showEmpty: false,
      tableLoading: false,
      dataForm: {
        page: 1,
        pagesize: 20,
        nameContains: '',
      },
      addSum: false,
      searchKey: false,
      saveKey: false,
      recordData: [],

      authPick: false,
      errImg: require('@/assets/nodata.png'),
      errInfo: '这里什么都没有发现',
      shouldShowErrimg: false,
    };
  },

  methods: {
    pageChange(type, vals) {
      if (type === 'size') {
        this.dataForm.pagesize = vals;
      } else {
        this.dataForm.page = vals;
      }
      this.refreshData();
    },
    async deleteData(id) {
      try {
        await resourceManagement.sponsorDeleteData(id);
        this.refreshData();
      } catch (err) {
        this.$message.error('删除失败！');
        return;
      }
    },
    // 监听哪一行的数据被修改了，如果被修改了则同步更新
    record(item) {
      this.recordData.includes(item) ? null : this.recordData.push(item);
    },
    async refreshData() {
      if (!this.authGroup.read) {
        return;
      }
      this.tableLoading = true;
      resourceManagement
        .sponsorData(this.dataForm)
        .then(res => {
          this.authPick = Number(res[2].status) === 403;
          if (this.authPick) {
            this.errImg = require('@/assets/noquanxian.png');
            this.errInfo = '抱歉，您暂无权限哦～';
            this.shouldShowErrimg = true;
            this.$message.closeAll();
            return;
          }
          if (!res[0].sponsors || !res[0].sponsors.length) {
            this.tableLoading = false;
            this.serviceTableData = [];
            this.showEmpty = true;
            return;
          }
          const result = res[0].sponsors;
          /*           this.countProjSummary = result.length; */
          this.serviceTableData = [...result];

          this.searchKey = false;
          this.tableLoading = false;
          this.showEmpty = false;
          this.count = res[0].count ? res[0].count : 0;
          this.addSum = false;
        })
        .catch(err => {
          console.log(err);
        });
    },
    //添加服务项
    addServiceItem() {
      if (this.addSum) {
        this.$message.error('请先保存新增行内容后再添加');
        return;
      }
      this.serviceTableData.unshift({ name: '', fullname: '', remark: '', type: 'new' });
      this.addSum = true;
    },
    //保存服务项
    async saveAllServiceItems() {
      this.requiredIndex = [];
      let errTip = [];
      const requiredField = this.serviceTableData.filter((item, index) => {
        if (!item.fullname || !item.name) {
          this.requiredIndex.push(index);
        }
        if (!item.fullname) errTip.push(1);
        if (!item.name) errTip.push(2);
        return !item.fullname || !item.name;
      });
      if (requiredField.length > 0) {
        errTip.includes(1) && errTip.includes(2)
          ? this.$message.error('申办方简称、申办方全称未填写')
          : errTip.includes(1)
          ? this.$message.error('申办方全称未填写')
          : this.$message.error('申办方简称未填写');
        return;
      }

      this.saveKey = true;
      if (!this.recordData.length) {
        return;
      }

      let submitData = {};
      submitData['sponsors'] = this.recordData;
      try {
        await resourceManagement.sponsorSaveData(submitData);
      } catch (err) {
        this.$message.error('保存项目失败！');
        return;
      }

      this.$message.success('保存成功！');
      this.recordData = [];
      this.refreshData();
    },
    searchForm() {
      if (this.searchKey) {
        return;
      }
      this.searchKey = true;
      const searchSubmit = debounce(this.refreshData, 800);
      this.dataForm.page = 1;
      this.dataForm.pagesize = 20;
      this.count = 0;
      searchSubmit();
    },

    handleSizeChange(val) {
      this.dataForm.pagesize = val;
      this.searchForm();
    },
    handleCurrentChange(val) {
      this.dataForm.page = val;
      this.searchForm();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/compsStyle/projectStatement.scss';
@import '@/styles/compsStyle/resource.scss';
@import '@/styles/element-ui.scss';
#sponsorManagement {
  @include resourceStyle;
  padding: 14px 0;
  padding-bottom: 0;
  border-radius: 2px;
  margin: 0 16px;
  margin-right: 24px;
  margin-top: 0;
  overflow-y: hidden;
}
</style>
