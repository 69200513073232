<template>
  <div id="serviceManagement" v-if="!authPick">
    <filter-pane ref="filterPane">
      <template v-slot:filter>
        <el-input v-model="dataForm.nameCont" placeholder="查找服务项明细" class="searchInput"></el-input>
        <my-select
          :options="categoryOption"
          optionValue="id"
          v-model="dataForm.categoryEq"
          :placeholder="'请选择分类'"
          style="width: 230px"
        ></my-select>
        <el-button type="default" size="mini" class="editButton" @click="clearItems">清空</el-button>
        <el-button type="primary" size="mini" @click="searchForm">{{ $t('ResourceManagement.customer.screen') }}</el-button>
        <div class="buttonGroup">
          <el-button v-if="ServiceManagementCreate" type="primary" size="mini" class="addButton" @click="serviceItem()">添加服务项</el-button>
        </div>
      </template>
    </filter-pane>
    <EditServiceItemTable
      :applyComp="'service'"
      :tableLoading.sync="tableLoading"
      :serviceTableData="serviceTableData"
      :chooseInputArr="chooseInputArr"
      :tableHeight="tableHeight"
      @deleteData="deleteData"
      :updatePermission="updatePermission"
      :deletePermission="deletePermission"
      :readPermission="readPermission"
      :editDiago="true"
      @editServiceItem="serviceItem"
      @readServiceItem="readServiceItem"
      :requiredIndex="requiredIndex"
      :saveKey.sync="saveKey"
      :page="dataForm.page"
      :pagesize="dataForm.pagesize"
      @pageChange="pageChange"
      :count="count"
    ></EditServiceItemTable>

    <SimpleDialog
      class="PlanDialog"
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="535px"
      :confirmBtnText="confirmBtnText"
      :confirmBtnLoading="dialogLoading"
      showConfirm
      showCancel
      @onCancel="dialogClose"
      @onSubmit="DialogSubmit"
      :before-close="dialogClose"
    >
      <el-form :model="DialogForm" ref="DialogForm" class="DialogForm" :rules="dialogRules" :label-position="labelPosition" label-width="150px">
        <el-form-item prop="category" label="服务项明细分类">
          <my-select :options="categoryOption" v-model="DialogForm.category" placeholder="请输入或者下拉选择服务项明细分类"></my-select>
        </el-form-item>
        <el-form-item prop="name" class="name" label="服务项明细名称">
          <el-input
            :maxlength="100"
            :rows="3"
            show-word-limit
            v-model.number="DialogForm.name"
            placeholder="请输入服务项明细名称"
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item prop="description" label="详细说明">
          <el-input :maxlength="500" :rows="6" show-word-limit v-model="DialogForm.description" placeholder="请输入" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-switch v-model="DialogForm.status" active-color="#13ce66" inactive-color="#ff4949"> </el-switch>
        </el-form-item>
      </el-form>
    </SimpleDialog>
    <el-drawer title="查看服务项" :visible.sync="readVisible" class="readServ" direction="rtl" size="50%">
      <div class="tableContainer">
        <el-descriptions class="margin-top descriptions" :column="2" border>
          <el-descriptions-item
            content-class-name="userInfo"
            :contentStyle="{ minWidth: '180px' }"
            :labelStyle="{ textAlign: 'left', color: '#52596C' }"
          >
            <template slot="label">服务项明细名称： </template>
            {{ readForm.name }}
          </el-descriptions-item>
          <el-descriptions-item
            content-class-name="userInfo"
            :contentStyle="{ minWidth: '180px' }"
            :labelStyle="{ textAlign: 'left', color: '#52596C' }"
          >
            <template slot="label"> 服务项明细分类： </template>
            {{ readForm.category }}
          </el-descriptions-item>
          <el-descriptions-item
            content-class-name="userInfo"
            :contentStyle="{ minWidth: '180px' }"
            :labelStyle="{ textAlign: 'left', color: '#52596C' }"
          >
            <template slot="label">详细说明： </template>
            {{ readForm.description }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </el-drawer>
  </div>
  <div v-else>
    <Empty v-if="shouldShowErrimg" :image="errImg" :description="errInfo" />
  </div>
</template>

<script>
import SimpleDialog from '@/components/common/SimpleDialog';
import MySelect from '@/components/common/MySelect';
import Empty from '@/components/Empty/index.vue';
import resourceManagement from '@/api/modules/resourceManagement.api.js';
import FilterPane from '@/views/project-data/components/FilterPane';
import EditServiceItemTable from './components/EditServiceItemTable';
import { mixinsResource } from '@/mixins/resourceData';

export default {
  components: {
    EditServiceItemTable,
    FilterPane,
    SimpleDialog,
    MySelect,
    Empty,
  },
  name: 'ServiceManagement',
  mixins: [mixinsResource],
  computed: {
    ServiceManagementCreate() {
      return this.$store.state.app.userConfig.permissionsKeys?.ServiceManagementCreate;
    },
    deletePermission() {
      return this.$store.state.app.userConfig.permissionsKeys?.ServiceManagementDelete;
    },
    updatePermission() {
      return this.$store.state.app.userConfig.permissionsKeys?.ServiceManagementUpdate;
    },
    readPermission() {
      return this.$store.state.app.userConfig.permissionsKeys?.ServiceManagementRead;
    },
  },
  data() {
    const validateName = (rule, value, callback) => {
      if (this.nameError) {
        callback(new Error('服务项明细名称已被占用'));
      } else if (!value) {
        callback(new Error('请输入服务项明细名称'));
      } else {
        callback();
      }
    };
    return {
      dialogTitle: '添加服务项',
      categoryOption: [],
      readVisible: false,
      confirmBtnText: '确定',
      nameError: false,
      DialogForm: {
        status: true,
        name: '',
        category: '',
        description: '',
      },
      readForm: {
        id: '',
        name: '',
        category: '',
        description: '',
      },
      labelPosition: 'right',
      dialogLoading: false,
      dialogVisible: false,
      dialogRules: {
        category: [{ required: true, type: 'string', message: '请输入或者下拉选择', trigger: 'blur' }],
        name: [{ type: 'string', required: true, validator: validateName, trigger: 'blur' }],
      },

      requiredIndex: [],
      serviceTableData: [],
      count: 0,
      chooseInputArr: [
        {
          prop: 'name',
          width: 400,
        },
        {
          prop: 'description',
          width: 340,
          tooltip: true,
        },
        {
          prop: 'chineseCategory',
        },
      ],
      choosedContractItem: {}, //当前选择的事项
      shouldShowErrimg: false,
      tableLoading: false,
      dataForm: {
        page: 1,
        pagesize: 20,
        nameCont: '',
        categoryEq: '',
      },
      editId: '',
      saveKey: false,
      recordData: [],

      authPick: false,
      errImg: require('@/assets/nodata.png'),
      errInfo: '这里什么都没有发现',
    };
  },
  created() {
    this.refreshData();
    this.initOption();
  },

  methods: {
    initOption() {
      return new Promise(() => {
        setTimeout(() => {
          this.categoryOption = [
            {
              name: '项目管理',
              id: 'project',
            },
            {
              name: '数据分析',
              id: 'data_analysis',
            },
            {
              name: '数据管理',
              id: 'data_manage',
            },
            {
              name: '质量管理',
              id: 'qa',
            },
            {
              name: '通用服务',
              id: 'common',
            },
            {
              name: '其他',
              id: 'other',
            },
          ];
        }, 0);
      });
    },
    clearItems() {
      this.dataForm.nameCont = '';
      this.dataForm.categoryEq = '';
    },
    pageChange(type, vals) {
      if (type === 'size') {
        this.dataForm.pagesize = vals;
      } else {
        this.dataForm.page = vals;
      }
      this.refreshData();
    },
    async deleteData(id) {
      try {
        await resourceManagement.deleteServiceData(id);
        this.refreshData();
      } catch (err) {
        this.$message.error('删除失败！');
        return;
      }
    },
    async refreshData() {
      this.tableLoading = true;
      resourceManagement
        .getServiceData(this.dataForm)
        .then(res => {
          if (!res[0].serviceItems || !res[0].serviceItems.length) {
            this.serviceTableData = [];
            this.shouldShowErrimg = true;
            this.tableLoading = false;
            return;
          }
          let result = res[0].serviceItems;
          result.forEach(item => {
            let cart = item.category;
            switch (cart) {
              case 'project':
                item.chineseCategory = '项目管理';
                break;
              case 'data_analysis':
                item.chineseCategory = '数据分析';
                break;
              case 'data_manage':
                item.chineseCategory = '数据管理';
                break;
              case 'qa':
                item.chineseCategory = '质量管理';
                break;
              case 'common':
                item.chineseCategory = '通用服务';
                break;
              case 'other':
                item.chineseCategory = '其他';
            }
          });
          this.serviceTableData = [...result];
          this.tableLoading = false;
          this.shouldShowErrimg = false;
          this.count = res[0].count ? res[0].count : 0;
          this.addSum = false;
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 查看服务项
    async readServiceItem(val) {
      console.log(val);
      this.readVisible = true;
      let [res] = await resourceManagement.getDetailServiceData(val.id);
      this.readForm = Object.assign({}, res);
    },
    //添加/编辑服务项
    async serviceItem(val) {
      this.initOption();
      if (val) {
        this.dialogTitle = '编辑服务项';
        console.log(val);

        this.DialogForm = Object.assign({}, val);
        this.editId = val.id;
      } else {
        this.dialogTitle = '添加服务项';
        this.DialogForm = {
          name: '',
          category: '',
          status: true,
          description: '',
        };
      }
      this.dialogVisible = true;
    },

    searchForm() {
      this.dataForm.page = 1;
      this.dataForm.pagesize = 20;
      this.count = 0;
      this.refreshData();
    },
    async DialogSubmit() {
      this.$refs.DialogForm.validate(async valid => {
        if (!valid) {
          return;
        }
        this.confirmBtnText = '提交中';
        this.dialogLoading = true;

        const res = this.editId
          ? await resourceManagement.editServiceData(this.editId, this.DialogForm)
          : await resourceManagement.addServiceData(this.DialogForm);
        this.dialogLoading = false;
        this.confirmBtnText = '确定';
        console.log(res[1]);

        if (!res[1].code) {
          this.nameError = false;
          this.dialogClose();
          await this.refreshData();
          this.$message.success(this.$t('commonVariables.submittedSuccessfully'));
          this.editId = '';
        } else if (res[1].metadata) {
          const errData = res[1].metadata;
          console.log(errData.name);

          if (errData.name && errData.name === 'not_uniq') {
            this.nameError = true;
          }
          this.editId ? null : this.$refs.DialogForm.validate();
          this.nameError = false;
        }
      });
    },
    dialogClose() {
      this.dialogVisible = false;
      this.$refs.DialogForm.resetFields();
      this.$nextTick(() => {
        this.$refs.DialogForm.clearValidate();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/compsStyle/projectStatement.scss';
@import '@/styles/element-ui.scss';
@import '@/styles/compsStyle/resource.scss';

#serviceManagement {
  @include resourceStyle;
  .editButton {
    margin-left: 16px;
  }
  ::v-deep .el-dialog__header {
    padding-top: 12px;
    padding-bottom: 12px;
    font-weight: 500;
    font-size: 16px;
    justify-content: center;
  }
  .DialogForm {
    padding-top: 32px;
    .el-form-item {
      height: initial;
      margin-bottom: 32px;
      margin-right: 32px;
    }
    .mySelect {
      width: 100%;
    }
    .userIds {
      display: block;
      .selectUser {
        width: 100%;
        ::v-deep .el-input__inner {
          height: 40px !important;
        }
      }
      .multiple {
        width: 100%;
        margin: 4px 0 0 0;
        display: flex;
        flex-wrap: wrap;
        .el-tag {
          margin: 0px 4px 4px 0;
        }
      }
    }
    .readServ {
      .tableContainer {
        padding: 23px 32px;
        width: 100%;
        max-height: 770px;

        .userInfo {
          min-width: 500px !important;
          color: rgba(0, 0, 0, 0.85) !important;
        }
        .descRadiogroup {
          .el-radio {
            color: rgba(0, 0, 0, 0.85);
          }
          .el-radio__input.is-checked + .el-radio__label {
            color: #409eff;
          }
        }
        .descriptions {
          border: 1px solid #e8e8e8;
          overflow-y: auto;
          height: 100%;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.85) !important;
          ::v-deep .el-descriptions-item__label {
            text-align: center;
            width: 184px;
          }
          ::v-deep .el-descriptions-item__content {
            padding-left: 24px;
          }
          ::v-deep .el-descriptions__table {
            color: rgba(0, 0, 0, 0.85) !important;
            tbody:first-of-type {
              th,
              td {
                border-top: none;
              }
            }
            tbody:last-of-type {
              th,
              td {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }
}
/* @include resourceEmpty; */
.PlanDialog {
  ::v-deep .el-textarea__inner {
    padding-bottom: 25px;
  }

  ::v-deep .el-dialog__footer {
    .footer {
      margin-right: 20px;
    }
  }
}
::v-deep #el-drawer__title {
  span {
    font-size: 18px;
    font-weight: 600;
    color: #19233b;
  }
}
</style>
